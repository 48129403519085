<template>
  <div id="runDayModal" class="modal fade" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">

          <div class="d-flex align-items-center p-3">
            <div class="centered-circle flex-shrink-0 bg-secondary text-primary h-10 w-10">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                   stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                   class="feather feather-code small-icon">
                <polyline points="16 18 22 12 16 6"></polyline>
                <polyline points="8 6 2 12 8 18"></polyline>
              </svg>
            </div>
            <div class="ms-3">
              <h3 class="fs-5 spacing-close">Run Active Markets</h3>
            </div>
          </div>

          <!-- Content Here -->
          <div>

            <form>

              <div class="mb-3">
                <label for="dayInput" class="form-label">Start Date</label>
                <input type="text" v-model="job.date" class="form-control" id="dayInput"
                       placeholder="yyyy-mm-dd">
              </div>

              <div class="mb-3">
                <label for="dayInput" class="form-label">End Date</label>
                <input type="text" v-model="job.endDate" class="form-control" id="dayInput"
                       placeholder="yyyy-mm-dd">
              </div>

            </form>

          </div>

        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary font-sm" style="min-width: 150px;" @click="onOkClick">Ok
          </button>
          <button type="button" class="btn btn-outline-primary font-sm" data-bs-dismiss="modal"
                  style="min-width: 150px;">Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'RunDayModal',
  props: {
    yesEvent: Function,
  },
  data() {
    return {
      model: streetmetrics.model,
      job: {
        date: '',
        endDate: ''
      },
      currentModal: null,
      modalElement: null,
      errorMessage: null,
    }
  },
  computed: {


  },
  mounted() {
    this.modalElement = document.getElementById('runDayModal')
    this.currentModal = new bootstrap.Modal(this.modalElement)

    this.modalElement.addEventListener('hidden.bs.modal', () => {
      this.$emit('noEvent')
    })

    this.currentModal.show()
  },
  beforeDestroy() {
    this.modalElement.removeEventListener('hidden.bs.modal')
  },
  methods: {
    onSaveClick() {
      this.errorMessage = null
    },

    noHandler(e) {
      // hide the modal
      this.currentModal.hide()
    },
    onOkClick() {

      if(!this.job.date || !this.isDateValid()) {
        alert('A valid date is required')
        return
      }

      // call the parent's "yesEvent"
      if (this.yesEvent) {
        this.yesEvent(this.job.date, this.job.endDate)
      }
      // hide the modal
      this.noHandler()
    },
    isDateValid() {
      return streetmetrics.utils.string.isValidDateString(this.job.date)
    }
  }
}
</script>

<style>

</style>
